import React, {FC, ReactNode} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Link from 'next/link'
import {Cart16Svg, CheckToastSvg, FailSvg} from '../../../../../svg'
import {cartAddItem, getUpOrCrossProd} from '../../../../../store/cart'
import {setCrossValid, setPopupName, setTempData,} from "../../../../../store/general";
import {IState} from "../../../../../types/state";
import {domainUrl} from '../../../../../helper'
import cls from "./customSearch.module.scss"
import Image from "next/image";
import {IProduct, TranslationsType} from "../../../../../types/homePageTypes";
import {useRouter} from "next/router";
import {ICurrencies} from "../../MainLayout";
import {useToast} from "../../../../../atoms/Toast/useToast";

interface IProps {
    products: Array<IProduct>;
    selectedRate: ICurrencies;
    dbName: string;
    translationsData: TranslationsType;
    backOrderValue: string;
    selectedLocale: string
    showCart: boolean
}

const CustomSuggestions: FC<IProps> = (
    {
        products,
        selectedRate,
        dbName,
        translationsData,
        backOrderValue,
        selectedLocale,
        showCart
    }
) => {
    const dispatch = useDispatch();
    const {push: routerPush} = useRouter();
    const toast = useToast();
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer)
    const upCrossSellHandle: (product: IProduct) => Record<string, Record<string, () => void>> = (product) => {
        return {
            "true": {
                "true": () => {
                    dispatch(setPopupName("upsell"));
                    dispatch(getUpOrCrossProd(product.id, 'up-sell', selectedLocale, "", 1, selectedRate))
                    dispatch(setTempData([product]));
                    dispatch(setCrossValid(false));
                },
                "false": () => upCrossSellHandle(product)["true"]["true"](),
            },
            "false": {
                "true": () => {
                    dispatch(getUpOrCrossProd(product.id, 'cross-sell', selectedLocale, "", 1, selectedRate))
                    dispatch(setPopupName("crossSell"));
                    dispatch(setTempData([product]));
                    dispatch(setCrossValid(true));
                },
                "false": () => {
                }
            }
        }
    }
    const openUpCrossProd = (product: IProduct) => {
        const {flats: [{has_up_sell = 0, has_cross_sell = 0} = {}]} = product
        return upCrossSellHandle(product)[`${!!parseFloat(has_up_sell)}`][`${!!parseFloat(has_cross_sell)}`]()
    }
    const hasProductLength = (list: ReactNode) => {
        return {
            "true": list,
            "false": (
                <div className={cls.search__fm_dr_Message}>
                    {translationsData?.NoMatchingItems}
                </div>
            )
        }
    }
    const inStockProducts = (list: ReactNode) => {
        return {
            "true": list,
            "false": null
        }
    }
    const hasProductBackOrders = {
        "true": {
            "true": cls["button_disabled"],
            "false": ""
        },
        "false": {
            "true": "",
            "false": ""
        }
    }
    const toastOpenHandle: Record<string, Record<string, () => void>> = {
        "true": {
            "true": () => toast.open(
                <span className="d-flex chek-fms">
                    <FailSvg/>
                    {translationsData.ProductNotAvailable}
                </span>
            ),
            "false": () => toastOpenHandle["true"]["true"]()
        },
        "false": {
            "true": () => toast.open(
                <span className="d-flex chek-fms">
                    <CheckToastSvg/>
                    {translationsData.ToastAddToCart}
               </span>
            ),
            "false": () => {}
        }
    }

    const addToast = (error?: boolean, isProductInStock?: boolean) => toastOpenHandle[`${error}`][`${isProductInStock}`]()
    const handleCardClick: (product: IProduct) => Record<string, () => void> = (product) => {
        const {flats: [{url_key}]} = product
        const isProductInStock = product.qty[0] > 0 || !!parseFloat(backOrderValue)
        return {
            "true": () => {
                openUpCrossProd(product)
                dispatch(cartAddItem(
                    product,
                    1,
                    cartToken,
                    customer as { token: string },
                    selectedLocale,
                    selectedRate.code,
                    null,
                    (error) => addToast(error, isProductInStock)
                ));
            },
            "false": () => {
                routerPush(`/${url_key}`)
                    .then(fulfilled => fulfilled)
            }
        }
    }

    const list = products.map((product: IProduct) => {
        const handlePrice = {
            true: Number(product.flats[0]?.special_price).toFixed(2),
            false: Number(product.flats[0]?.price).toFixed(2)
        }

        const hasCart = {
            "true": <div className={`${cls["suggestions__item-actions"]} ${hasProductBackOrders[`${product.qty[0] === 0}`][`${parseFloat(backOrderValue) === 0}`]}`}>
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        handleCardClick(product)[`${product?.type !== "configurable" || product.qty[0] > 0 || !!parseFloat(backOrderValue)}`]();
                    }}
                    className={`btn btn-primary btn-sm btn-svg-icon ${cls["suggestion-btn"]}`}
                >
                    <Cart16Svg/>
                </button>
            </div>,
            "false": null
        }

        return inStockProducts(
            <Link
                className={cls["suggestions__item-name"]}
                href={`/${product.flats[0].url_key}`}
                key={product.id}
            >
                <li className={cls.suggestions__item}>
                    <div className={`${cls["suggestions__item-image"]} ${cls["product-image"]}`}>
                        <div className={`${cls["search-product-image__body-fms"]}`}>
                            <Link href={`/${product.flats[0].url_key}`}>
                                <a>
                                    <Image
                                        src={domainUrl(`${dbName}${product?.images[0]}`)}
                                        alt={product?.flats[0]?.name}
                                        width={50}
                                        height={50}
                                        priority
                                    />
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className={cls["suggestions__item-info"]}>
                        <a> {product?.flats[0]?.name}</a>
                        <div className={cls["suggestions__item-meta"]}>
                            {`SKU: ${product?.sku}`}
                        </div>
                    </div>
                    <div className={cls["suggestions__item-price"]}>
                        {selectedRate?.symbol}
                        {" "}
                        {handlePrice[`${!!product.flats[0]?.special_price}`]}
                        {' '}
                    </div>
                    {hasCart[`${showCart}`]}
                </li>
            </Link>
        )[`${product.type === "configurable" || product.qty[0] > 0}`]
    })

    return (
        <div className={`${cls.suggestions} ${cls[`suggestions--location--header`]} ${cls.search__suggestions}`}>
            <ul className={cls["suggestions__list"]}>{hasProductLength(list)[`${products.length > 0}`]}</ul>
        </div>
    )
};

export default CustomSuggestions