import React, {FC, Fragment} from "react"
import {useSelector} from "react-redux";
import {IState} from "../../../../../types/state";
import CustomIndicatorAccount from "./CustomIndicatorAccount";
import cls from "../customHeader.module.scss"
import CustomIndicator from "./CustomIndicator";
import CustomFailPanel from "./CustomFailPanel";
import {TranslationsType} from "../../../../../types/homePageTypes";
import {ICurrencies} from "../../MainLayout";

interface IIndicatorPanel {
    isSocialLinksActive: Array<Record<string, string>>;
    translationsData: TranslationsType;
    selectedLocale: string;
    selectedRate: ICurrencies;
    dbName: string
    showAccount: boolean
    showWishlist: boolean
}

const CustomIndicatorPanel: FC<IIndicatorPanel> = ({
                                                       translationsData,
                                                       selectedLocale,
                                                       selectedRate,
                                                       isSocialLinksActive,
                                                       dbName,
                                                       showAccount,
                                                       showWishlist
                                                   }) => {
    const signed = useSelector((state: IState) => state.customer.authenticated);
    const wishlist = useSelector((state: IState) => state.wishlist);
    const wishIndicator = {
        "true": {
            "true": <CustomIndicator
                url="/wishlist"
                value={wishlist.length}
                icon="heartIcon"
                title={translationsData?.WishList}
                className=""
                dropdown=""
                iconWrapperClassName=""
            />,
            "false": <CustomFailPanel translationsData={translationsData}/>
        },
        "false": {
            "true": null,
            "false": null
        }
    }

    const handleShowAccountIcon = {
        "true": <div className={cls["nav-panel__item_row"]}>
            <CustomIndicatorAccount
                isSocialLinksActive={isSocialLinksActive}
                translationsData={translationsData}
                selectedLocale={selectedLocale}
                selectedRate={selectedRate}
                dbName={dbName}
            />
        </div>,
        "false": null
    }

    return (
        <Fragment>
            {/*{searchIndicator}*/}
            {handleShowAccountIcon[`${showAccount}`]}
            <div className={`${cls["nav-panel__item_row"]} ${cls.heartButton}`}>
                {wishIndicator[`${showWishlist}`][`${signed}`]}
            </div>
        </Fragment>
    )
}

export default CustomIndicatorPanel;